import React, { FC } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

export const ProtectedRoute: FC<{
  isAuth: boolean | null;
  to: string;
}> = ({ isAuth, to }) => {
  const location = useLocation();
  const navigateState = !isAuth ? { from: location.pathname, search: location.search } : {};

  if (isAuth && location.pathname === ROUTES.home) return <Navigate to={ROUTES.dashboard} />;

  return isAuth ? <Outlet /> : <Navigate to={to} state={navigateState} replace />;
};
