import { useEffect, useMemo, useState } from "react";

import { Option } from "interfaces/shared/form-elements.interfaces";

import { getPersonalizationOptionsList } from "storage/actions/personalization";
import { selectPersonalizationOptions } from "storage/selectors/personalization";

import { useAppDispatch, useAppSelector } from "../appHooks";

export const useLoadPersonalizationOptionsList = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const personalization = useAppSelector(selectPersonalizationOptions);

  const preparedOptions = useMemo(() => {
    const formattedHeardSources: Option[] =
      personalization?.heard_sources?.map(({ heard_id, name }) => ({
        label: name,
        value: heard_id,
      })) || [];

    const formattedRoles: Option[] =
      personalization?.roles?.map(({ role_id, name, icon }) => ({
        label: `${icon}\xa0\xa0 ${name}`,
        value: role_id,
      })) || [];

    const formattedUsages: Option[] =
      personalization?.usages?.map(({ usage_id, name }) => ({
        label: name,
        value: usage_id,
      })) || [];

    return { heardSources: formattedHeardSources, roles: formattedRoles, usages: formattedUsages };
  }, [personalization]);

  useEffect(() => {
    if (personalization) return;

    setIsLoading(true);
    dispatch(getPersonalizationOptionsList())
      .unwrap()
      .finally(() => setIsLoading(false));
  }, [dispatch, personalization]);

  return { isLoading, personalizationOptions: preparedOptions };
};
