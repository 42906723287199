import cn from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import { TypographyVariants } from "constants/shared/typography.constants";

import { MembershipPlan, MembershipPlanDetails } from "interfaces/memberships.interfaces";

import { getMembershipPlanPrice } from "utils/memberships.utils";

import { useAppDispatch } from "hooks/appHooks";

import { getPaymentUrl } from "storage/actions/payments";

import { Button } from "shared/components/buttons/Button";
import { Typography } from "shared/components/Typography";

import { ButtonVariants } from "../../constants/shared/button.constants";
import { UserPlans } from "../../constants/user.constants";

import { MembershipAdvantagesList } from "./memberships-advantages/MembershipAdvantagesList";

interface Props extends MembershipPlan, MembershipPlanDetails {
  isActive?: boolean;
  isYearly?: boolean;
  isFreemiumPlan?: boolean;
}

export const MembershipCard = ({
  isActive,
  isYearly,
  description,
  priceLabel,
  advantages,
  showSaveLabel,
  isFreemiumPlan,
  name_public: planName,
  name_tech: planTechName,
  price_monthly: priceMonthly,
  price_yearly: priceYearly,
  icon: Icon,
  monthly_product_id: monthlyProductId,
  yearly_product_id: yearlyProductId,
}: Props) => {
  const dispatch = useAppDispatch();
  const planPrice = getMembershipPlanPrice({ priceYearly, priceMonthly, isYearly });

  const isFreeDisabled = planTechName === UserPlans.FREE && isFreemiumPlan;

  const isActivePayButton = Boolean(isYearly ? yearlyProductId : monthlyProductId) && !isFreeDisabled;

  const handleClickPaymentButton = () => {
    const priceId = isYearly ? yearlyProductId : monthlyProductId;

    dispatch(getPaymentUrl({ priceId }))
      .unwrap()
      .then(({ url }) => window.open(url, "_self"));
  };

  return (
    <div
      className={twMerge(
        cn("flex flex-col min-w-80 p-6 xl:p-9 basis-96 rounded-[28px] bg-[#FCFCFC] border border-[#D5D1F7] font-interTight", {
          "border-2 border-[#766CE4]": isActive,
        }),
      )}
    >
      <div className='flex flex-col grow'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center'>
            <div className='flex justify-center items-center w-12 h-12 bg-[#766CE4] rounded-2xl text-white'>
              <Icon />
            </div>
            <Typography className='ml-3 text-[#292447]' variant={TypographyVariants.H5}>
              {planName}
            </Typography>
          </div>
          {isYearly && showSaveLabel && (
            <div className='w-fit py-1.5 px-2 bg-[#FDFDFD] uppercase border border-[#6B62CF] rounded-lg text-sm font-semibold text-[#6B62CF] select-none'>
              Save 20%
            </div>
          )}
        </div>
        <div className='mt-5 text-lg font-medium text-[#292447] font-plusJakartaSans'>
          <Typography className='mr-2' variant={TypographyVariants.H1}>
            {planPrice}
          </Typography>
          {priceLabel(isYearly)}
        </div>
        <Typography className='text-[#292447] mt-4' variant={TypographyVariants.LG_MEDIUM}>
          {description}
        </Typography>
      </div>
      <Button
        className='mt-4'
        variant={isActive ? ButtonVariants.MEDIUM_PREMIUM : ButtonVariants.LARGE_STROKED}
        onClick={handleClickPaymentButton}
        disabled={!isActivePayButton || isFreeDisabled}
      >
        Get early access
      </Button>

      <MembershipAdvantagesList advantagesList={advantages} />
    </div>
  );
};
