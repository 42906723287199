import { Description, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { ModalStyles, ModalVariants } from "constants/shared/modal.constants";

import { ReactComponent as CloseIcon } from "assets/icons/closeButton.svg";

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  children: ReactNode;
  title: string;
  titleClass?: string;
  popupClass?: string;
  description?: string;
  variant?: ModalVariants;
}

export const Modal = ({ isOpen, onClose, children, title, titleClass, popupClass, description, variant = ModalVariants.PRIMARY }: Props) => {
  return (
    <Dialog
      className='fixed z-40 inset-0 flex w-screen bg-[#1F2228]/25 backdrop-blur items-center justify-center p-4 transition duration-200 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0'
      open={isOpen}
      onClose={onClose}
      transition
    >
      <div className='fixed inset-0 w-screen overflow-y-auto p-4'>
        <div className='flex min-h-full items-center justify-center'>
          <DialogPanel className={twMerge("relative w-full border bg-white rounded-3xl p-8 shadow-shadow1 -mt-32", ModalStyles[variant], popupClass)}>
            <button className='absolute right-6 top-6' onClick={onClose}>
              <CloseIcon />
            </button>
            <DialogTitle className={"text-[28px] text-[#1F2228] font-semibold leading-tight text-center" + " " + titleClass}>
              {title}
            </DialogTitle>
            {description && <Description className='mt-6 text-[#5E636E] text-sm font-medium leading-7 text-center'>{description}</Description>}
            <div className='mt-10'>{children}</div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
