import api from "..";

import { IChannelData, ICreateBudget, IGoalData } from "./types";

const BASE_API = process.env.REACT_APP_API_URL;

export const getGoalsData = async (): Promise<IGoalData[]> => {
  const data: IGoalData[] = await api(`${BASE_API}/projects/goals`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

export const getChannelsData = async (): Promise<IChannelData[]> => {
  const data: IChannelData[] = await api(`${BASE_API}/projects/channels`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

// export const createBudget = async (projectData: ICreateBudget): Promise<any> => {
//   return api(`${BASE_API}/channels/generate-budget`, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify(projectData),
//   });
// };
