import type { FC } from "react";

import { ReactComponent as DownArrowIcon } from "../../../../../../assets/icons/arrowRightIcon.svg";
import { ReactComponent as DollarIcon } from "../../../../../../assets/icons/dollar.svg";
import { ReactComponent as FilterDownIcon } from "../../../../../../assets/icons/downFilterIcon.svg";
import { ReactComponent as FilterIcon } from "../../../../../../assets/icons/filter.svg";
import socialsImg from "../../../../../../assets/icons/socials.png";
import { useAppSelector } from "../../../../../../hooks/appHooks";
import CurveChart from "../../../../../../shared/components/budget/curveChart/CurveChart";

import classes from "./Metrics.module.scss";

interface MetricsProps {
  openChat: boolean;
}

const Metrics: FC<MetricsProps> = ({ openChat = false }) => {
  const { isCollapsed } = useAppSelector(state => state.sidebar);
  return (
    <div>
      <div className={classes.metrics__top}>
        <div className="flex gap-3 items-center">
          <h2 className={classes.metrics__top__title}>Metrics</h2>
          <div className={classes.metrics__comingSoon}>Coming Soon...</div>
        </div>
        <div className={classes.metrics__top__filterBlock}>
          <FilterIcon /> Cost per Lead <FilterDownIcon />
        </div>
      </div>
      <div className={classes.metrics}>
        <div className={classes.metrics__modal}>
          <div className={classes.metrics__modal__leftSide}>
            <h3>Transform your data into insights</h3>
            <p>
              Getting your data into Brainvoy is the important step to get insights you need. Easily connect your marketing platforms now in
              just a few clicks.
            </p>
            <button>Connect Data Source +</button>
          </div>
          <div className={classes.metrics__modal__rightSide}>
            <img src={socialsImg} alt='socials' />
          </div>
        </div>
        <div className={classes.metrics__metrics}>
          <div className={classes.metrics__metrics__chart}>
            <div className={classes.metrics__metrics__chart__top}>
              <div className={classes.metrics__metrics__chart__top__left}>
                <h3 className={classes.metrics__metrics__chart__top__left__title}>New Cost Per Lead</h3>
                <div className={classes.metrics__metrics__chart__top__left__info}>
                  <span>$50K</span>
                  <div className={classes.metrics__metrics__chart__top__left__info__trend}>
                    Q1-Q3 <DownArrowIcon />
                  </div>
                </div>
              </div>
              <div className={classes.metrics__metrics__chart__top__right}>
                <button>
                  <DollarIcon />
                </button>
              </div>
            </div>
            <CurveChart isCollapsed={isCollapsed} openChart={openChat} />
          </div>

          <div className={classes.metrics__metrics__chart}>
            <div className={classes.metrics__metrics__chart__top}>
              <div className={classes.metrics__metrics__chart__top__left}>
                <h3 className={classes.metrics__metrics__chart__top__left__title}>Existing Cost Per Lead</h3>
                <div className={classes.metrics__metrics__chart__top__left__info}>
                  <span>$50K</span>
                  <div className={classes.metrics__metrics__chart__top__left__info__trend}>
                    Q3 <DownArrowIcon />
                  </div>
                </div>
              </div>
              <div className={classes.metrics__metrics__chart__top__right}>
                <button>
                  <DollarIcon />
                </button>
              </div>
            </div>
            <CurveChart isCollapsed={isCollapsed} openChart={openChat} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;
