import React, { useEffect, useMemo, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppSelector } from "hooks/appHooks";
import { useChannelStrategy } from "hooks/channels/useChannelStrategy";

import Alert from "shared/components/toasts";
import { Typography } from "shared/components/Typography";

import { ReactComponent as GrowRateIcon } from "assets/icons/growRateMarketingIcon.svg";
import { ReactComponent as WalletIcon } from "assets/icons/marketingWalletIcon.svg";
import { ReactComponent as RevenueMarketingIcon } from "assets/icons/revenueMarketingIcon.svg";

import { RevenueFormattedValue } from "../ExecutiveView/RevenueFormattedValue";

import ChannelStrategy from "./ChannelStrategy/ChannelStrategy";
import classes from "./MarketingView.module.scss";
import MonthlyStrategy from "./MonthlyStrategy/MonthlyStrategy";

interface MarketingViewProps {
  openChat: boolean;
}

const MarketingView: FC<MarketingViewProps> = ({ openChat = false }) => {
  const navigate = useNavigate();

  const project = useAppSelector(state => state.project.project);

  const [transformedData] = useChannelStrategy({ budgetData: project?.project_data?.budget_data });

  const totalBudget = useMemo(() => {
    return project?.project_data?.budget_data?.reduce((acc: number, item: any) => {
      item?.channels?.forEach((item: any) => (acc += item.value_new));
      return acc;
    }, 0);
  }, [project]);

  useEffect(() => {
    if (
      !(
        project?.hasOwnProperty("project_data") &&
        Array.isArray(project?.project_data?.budget_data) &&
        project?.project_data?.budget_data.length > 0
      )
    ) {
      navigate(ROUTES.budget);
      Alert("error", "Current budget is not valid", "Error");
    }
  }, [project, navigate]);
  return (
    <div className={classes.marketingView}>
      <div className='relative w-[100%] flex items-center justify-between flex-grow gap-x-4'>
        <div className='flex flex-1 items-center justify-between h-[88px] bg-[#fff] rounded-2xl pt-4 pr-4 pb-6 pl-6 gap-x-2'>
          <div className='flex flex-col gap-y-2'>
            <p className='text-[#5E636E] text-sm font-normal'>Marketing Budget</p>
            <p className='text-[#14191A] text-2xl font-semibold '>${totalBudget?.toLocaleString("en-US")}</p>
          </div>
          <WalletIcon className='self-start' />
        </div>
        <div className='flex flex-1 items-center justify-between h-[88px] bg-[#fff] rounded-2xl pt-4 pr-4 pb-6 pl-6 gap-x-2'>
          <div className='flex flex-col gap-y-2'>
            <p className='text-[#5E636E] text-sm font-normal'>Projected Revenue</p>

            <RevenueFormattedValue
              className='[&_span]:!text-[#14191A] [&_span]:!text-2xl [&_span]:!font-semibold m-0'
              from={project?.revenue_from}
              to={project?.revenue_to}
            />
          </div>
          <RevenueMarketingIcon className='self-start' />
        </div>
        <div className='flex flex-1 items-center justify-between h-[88px] bg-[#fff] rounded-2xl pt-4 pr-4 pb-6 pl-6 gap-x-2'>
          <div className='flex flex-col gap-y-2'>
            <p className='text-[#5E636E] text-sm font-normal'>Revenue Growth Rate</p>

            <p className='text-[#14191A] text-2xl font-semibold'>
              {project?.revenue_rate ? `${project?.revenue_rate || "--"}% YoY` : <span className='text-[#9EA2AD]'>Not Provided</span>}
            </p>
          </div>
          <GrowRateIcon className='self-start' />
        </div>
      </div>
      <MonthlyStrategy transformedData={transformedData} />
      <div className='w-full'>
        <Typography className='mb-3' variant={TypographyVariants.H5}>
          Channel Strategy
        </Typography>
        <ChannelStrategy data={transformedData} hideToolbar redirectChannelAction />
      </div>
    </div>
  );
};

export default MarketingView;
