import React from "react";
import { twMerge } from "tailwind-merge";

import { TypographyVariants } from "constants/shared/typography.constants";

import { NumberOrNull } from "interfaces/shared-types.interfaces";

import { Typography } from "shared/components/Typography";

interface Props {
  from?: NumberOrNull;
  to?: NumberOrNull;
  className?: string;
}

export const RevenueFormattedValue = ({ from, to, className }: Props) => {
  const formatRevenue = (revenue?: NumberOrNull) => {
    if (!revenue) return "";

    if (revenue < 1000) {
      return revenue?.toString();
    } else if (revenue < 1000000) {
      return (revenue / 1000).toFixed(0) + "K";
    } else {
      return (revenue / 1000000).toFixed(0) + "M";
    }
  };

  return (
    <p className={twMerge("text-lg text-[#1F2228] font-bold my-4", className)}>
      {!from && !to ? (
        <span className='text-[#9EA2AD]'>Not Provided</span>
      ) : (
        // <Typography tag='span' variant={TypographyVariants.MD_BOLD}>
        //   ${formatRevenue(from)} - ${formatRevenue(to)}
        // </Typography>
        <h2 className="font-semibold text-2xl">${formatRevenue(from)} - ${formatRevenue(to)}</h2>
      )}
    </p>
  );
};
