import type { FC } from "react";

import BrainvoySuggest from "./BrainvoySuggest/BrainvoySuggest";
import BudgetAllocation from "./BudgetAllocation/BudgetAllocation";
import classes from "./ExecutiveView.module.scss";
import Recomendations from "./Recomendations/Recomendations";

interface ExecutiveViewProps {
  openChat: boolean;
}

const ExecutiveView: FC<ExecutiveViewProps> = ({ openChat = false }) => {
  return (
    <div className={classes.executiveView}>
      <BrainvoySuggest />
      <Recomendations openChat={openChat} />
      <BudgetAllocation openChat={openChat} />
    </div>
  );
};

export default ExecutiveView;
