import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";

import { BoxVariants } from "constants/shared/box.constants";
import { ButtonVariants } from "constants/shared/button.constants";

import { capitalizeFirstLetter } from "utils/shared.utils";

import { usePerformancePlan } from "hooks/performance-plans/usePerformancePlan";

import { Accordion } from "shared/components/accordion/Accordion";
import { Box } from "shared/components/Box";
import { Button } from "shared/components/buttons/Button";
import { CheckMarkToggle } from "shared/components/CheckMarkToggle";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";

import { PerformancePlanFormField } from "../performance-plan-form/PerformancePlanFormField";

export const PerformancePlanSidebar = () => {
  const navigate = useNavigate();
  const { workflowId, workflowData, formMethods, typesToRender, isSubmitDisabled, onSubmit } = usePerformancePlan();

  const { watch, formState } = formMethods;

  const handleNavigateBack = () => navigate(-1);

  return (
    <Box
      variant={BoxVariants.SIMPLIFIED}
      className='w-full min-w-[282px] max-w-[282px] sticky top-0 max-h-[calc(100vh-60px)] overflow-y-auto border-t-2 border-l-2 border-[#F7F9FB]'
      childrenClassName='flex flex-col justify-between h-full'
    >
      <FormProvider {...formMethods}>
        <div className='h-[90%] overflow-y-auto'>
          <form onSubmit={onSubmit}>
            <button
              className='mb-8 flex items-center text-[#766CE4] text-sm leading-tight font-semibold transform [&_svg]:hover:-translate-x-0.5 [&_svg]:transition-all [&_svg]:hover:duration-400'
              onClick={handleNavigateBack}
            >
              <ArrowDownIcon className='mr-2 rotate-90 text-[#766CE4]' />
              Back to Workflow
            </button>
            <div className='space-y-2'>
              {typesToRender?.map(name => (
                <Accordion
                  key={name}
                  label={capitalizeFirstLetter(name)}
                  additionalButtonNode={<CheckMarkToggle filled={Boolean(watch(name) && !formState?.errors?.[name])} />}
                >
                  <PerformancePlanFormField type={name} disabled={Boolean(workflowId && workflowData)} />
                </Accordion>
              ))}
            </div>
          </form>
        </div>
        <Button onClick={onSubmit} variant={ButtonVariants.MEDIUM} disabled={isSubmitDisabled}>
          Generate
        </Button>
      </FormProvider>
    </Box>
  );
};
