import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "./Editor.css";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";

import { updateWorkflow } from "api/workflows";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { setUnsaved } from "storage/slices/editor-slice";
interface TextEditorProps {
  initialValue?: string;
  // classes?: string;
  onChange?: (value: string) => void;
}

// Define the modules and formats outside the component
const modules = {
  toolbar: [
    // ["undo", "redo"], // Custom undo/redo buttons
    [{ header: [1, 2, false] }],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }, "link", "image"],
    // [{ color: [] }, { background: [] }],
    // [{ script: "sub" }, { script: "super" }, ],
    // ["link", "image", "video"],
    // ["clean"], // Remove formatting button
  ],
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 1000, // Time between updates to the undo stack
    maxStack: 50, // Maximum undo stack depth
    userOnly: true, // Apply only to user actions
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "script",
  "align",
];

const TextEditor: React.FC<TextEditorProps> = ({ initialValue = "Hello this is a text", onChange }) => {
  const dispatch = useAppDispatch();
  const { workflow } = useAppSelector(state => state.workflows);
  const [editorContent, setEditorContent] = useState<string>(initialValue);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const { id } = useParams();

  useEffect(() => {
    if (firstRender) setFirstRender(false);
    else dispatch(setUnsaved(true));  

    const timeoutId = setTimeout(() => {
      if (id) {
        updateWorkflow(Number(id), { workflow_data: editorContent, additional_fields: workflow?.additional_fields }).then(() =>
          dispatch(setUnsaved(false)),
        );
      }
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [editorContent]);

  return (
    <ReactQuill
      // value={delta}
      defaultValue={initialValue}
      onChange={value => setEditorContent(value)}
      modules={modules} // Pass modules here
      formats={formats} // Pass formats here
      theme='snow' // You can also use 'bubble'
      // className={classes}
    />
  );
};

export default TextEditor;
