import cn from "classnames";
import React, { ReactNode } from "react";

import { useAppSelector } from "hooks/appHooks";

import { RootState } from "storage/store";

import { Header } from "shared/components/Header";

import Popup from "../components/basePopup/Popup";
import SpinnerLoader from "../components/loader-screen/SpinnerLoader";
import NavBar from "../components/side-navbar/NavBar";

const MAIN_CLASSNAME = "flex w-full min-h-[100dvh] h-[auto] flex-grow relative bg-[#F7F9FB]";

interface Props {
  children: ReactNode;
  title?: string;
}

export const DashboardLayout = ({ children, title }: Props) => {
  const popupData = useAppSelector((state: RootState) => state.popup);
  const loading = useAppSelector(state => state.loader.isLoading);

  if (loading)
    return (
      <main className={MAIN_CLASSNAME}>
        <SpinnerLoader />
      </main>
    );

  return (
    <>
      <main className={cn(MAIN_CLASSNAME, { "blur-sm": popupData?.isOpen })}>
        <NavBar />
        <div className='flex flex-col flex-grow min-h-full flex-auto relative'>
          <Header title={title} />
          <div className='relative h-[calc(100vh-80px)] overflow-y-auto'>{children}</div>
        </div>
      </main>
      {popupData.isOpen && <Popup open={popupData.isOpen} content={popupData.content} />}
    </>
  );
};
