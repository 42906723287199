import React from "react";

import CreateBudgetPopup from "../popups/createBudgetPopup/CreateBudgetPopup";
import UploadBudgetPopup from "../popups/uploadBudgetPopup/UploadBudgetPopup";
import UploadNewBudgetPopup from "../popups/uploadNewBudgetPopup/UploadNewBudgetPopup";

interface PopupProps {
  open: boolean;
  content: string;
}

export default function Popup({ open, content }: PopupProps) {
  if (!open) return null;
  let Component;
  switch (content) {
    case "CreateBudget":
      Component = CreateBudgetPopup;
      break;
    case "UploadBudget":
      Component = UploadBudgetPopup;
      break;
    case "UploadNewBudget":
      Component = UploadNewBudgetPopup;
      break;

    default:
      return null;
  }
  return (
    <div className='size-full fixed flex flex-col justify-center items-center top-0 start-0 z-[80] overflow-x-hidden bg-[rgba(48,44,52,0.34)] '>
      <div className='flex flex-col justify-center hs-overlay-open:mt-7 hs-overlay-open:duration-500 opacity-1 ease-out transition-all max-w-[814px] w-fit h-[664px]'>
        {<Component />}
      </div>
    </div>
  );
}
