import React, { useEffect } from "react";

import { ReactComponent as CSVIcon } from "../../../assets/icons/csvIcon.svg";
import { ReactComponent as ExcelIcon } from "../../../assets/icons/excelIcon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trashIcon.svg";
import "./style.css";

interface IFileLoaderProps {
  name: string | undefined;
  format: string | undefined;
  progress: number;
  status: string;
  handleUpload: () => void;
  clearFileInput: () => void;
}

export default function FileLoader({ progress, status, handleUpload, name, format, clearFileInput }: IFileLoaderProps) {
  return (
    <div className={" w-[328px] h-[89px] border-2 border-[#E8E9F0] rounded-[20px] p-4 mb-11 "}>
      <div className='flex justify-between items-start'>
        <div className='flex flex-col w-full'>
          <div className='flex items-start gap-x-3 w-full justify-between'>
            <div className='flex items-start gap-x-3 '>
              <span
                className='size-8 flex justify-center items-start
               text-gray-500 rounded-lg'
              >
                {format === "csv" ? <CSVIcon className='size-8' /> : <ExcelIcon className='size-8' />}
              </span>
              <div className='flex flex-col gap-y-1'>
                <div className='flex items-start font-bold leading-4 text-sm'>
                  {status === "done" ? "Your file is ready" : "Processing"}
                </div>
                <p className='text-xs font-normal text-[#717684]'>
                  {name && (name?.length > 30 ? `... ${name?.slice(-27)}` : name)}
                </p>
              </div>
            </div>

            <div className='inline-flex items-start gap-x-2'>
              <TrashIcon onClick={() => clearFileInput()} className='cursor-pointer' />
            </div>
          </div>
        </div>
      </div>

      <div className='flex items-center gap-x-3 whitespace-nowrap maw-x-[296px]'>
        <div
          className='flex w-full h-2 bg-gray-200 rounded-full overflow-hidden max-w-[240px]'
          role='progressbar'
          aria-valuenow={1}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          <div
            className={`flex flex-col justify-center rounded-full overflow-hidden max-w-[240px] ${
              progress === 100 ? "bg-[#35D98A]" : "bg-[#766CE4]"
            }  text-xs text-white text-center whitespace-nowrap transition duration-500`}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <div className='w-6 text-end'>
          <span className='text-sm text-gray-800'>{progress}%</span>
        </div>
      </div>
    </div>
  );
}
