import React from "react";

import { MEMBERSHIPS_PLANS_DETAILS } from "constants/memberships.constants";

import { useAppSelector } from "hooks/appHooks";
import { useLoadMembershipsPlans } from "hooks/subscriptions/useLoadMembershipsPlans";

import { selectIsFreemiumPlan } from "storage/selectors/user";

import { MembershipCard } from "./MembershipCard";

interface Props {
  isYearly?: boolean;
}

export const MembershipCardsContainer = ({ isYearly }: Props) => {
  const { membershipsPlans } = useLoadMembershipsPlans();
  const isFreemiumMembershipPlan = useAppSelector(selectIsFreemiumPlan);

  return (
    <div className='flex space-x-6 xl:space-x-8 overflow-x-auto w-80 min-w-full'>
      {membershipsPlans?.length
        ? membershipsPlans.map((plan, index) => (
            <MembershipCard
              key={plan.plan_id}
              isActive={index === 1}
              isYearly={isYearly}
              isFreemiumPlan={isFreemiumMembershipPlan}
              {...plan}
              {...MEMBERSHIPS_PLANS_DETAILS[plan.name_tech]}
            />
          ))
        : null}
    </div>
  );
};
