import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { apiCreateWorkflow, apiGenerateWorkflow, apiGetAllWorkflows, apiGetWorkflow, getWorkflowsStrategy } from "api/workflows";

import { Meta } from "interfaces/meta.interfaces";
import { StringOrNumber } from "interfaces/shared-types.interfaces";
import { CreateWorkflowPayload, GenerateWorkflowPayload, Workflow } from "interfaces/workflows.interfaces";

export const WORKFLOWS_SLICE_NAME = "workflows";

export const getWorkflowsStrategyById = createAsyncThunk(
  `${WORKFLOWS_SLICE_NAME}/getWorkflowsStrategyById`,
  async (payload: { id: number }) => getWorkflowsStrategy(payload),
);

export const getWorkflow = createAsyncThunk(`${WORKFLOWS_SLICE_NAME}/getWorkflow`, async (id: StringOrNumber, { dispatch }) => {
  const response = await apiGetWorkflow(id);

  return response;
});

export const createWorkflow = createAsyncThunk(
  `${WORKFLOWS_SLICE_NAME}/createWorkflow`,
  async (payload: CreateWorkflowPayload, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiCreateWorkflow(payload);

      dispatch(setWorkflow(response));

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const generateWorkflow = createAsyncThunk(
  `${WORKFLOWS_SLICE_NAME}/generateWorkflow`,
  async (payload: GenerateWorkflowPayload, { rejectWithValue }) => {
    try {
      return apiGenerateWorkflow(payload);
    } catch (err) {
      // TODO - create function for error handling
      return rejectWithValue(err);
    }
  },
);

export const getAllWorkflows = createAsyncThunk(`${WORKFLOWS_SLICE_NAME}/getAllWorkflows`, (payload?: Meta) => apiGetAllWorkflows(payload));

export const setWorkflow = createAction<Workflow | null>("SET_WORKFLOW");

export const setIsWorkflowsLoaded = createAction<boolean | null>("SET_IS_WORKFLOWS_LOADED");
