import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { signUp } from "api/auth";
import { IUserRegister } from "api/auth/types";

import { USER_WITH_EMAIL_ALREADY_EXISTS_ERROR_MESSAGE } from "constants/errors.constants";
import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppDispatch } from "hooks/appHooks";
import { useGoogleLoginRequest } from "hooks/useGoogleLogin";

import { getMeData } from "storage/actions/user";

import GoogleButton from "shared/components/auth-buttons/GoogleButton";
import { Button } from "shared/components/buttons/Button";
import { LinkButton } from "shared/components/buttons/LinkButton";
import { Input } from "shared/components/form-elements/Input";
import Alert from "shared/components/toasts";
import { Typography } from "shared/components/Typography";

import { ReactComponent as CheckIcon } from "assets/icons/CheckIcon.svg";
import {ReactComponent as InvitationCodeIcon} from "assets/icons/invitation-code.svg"
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as InputProfileIcon } from "assets/icons/ProfileInput.svg";
import { ReactComponent as EmailIcon } from "assets/icons/sms.svg";

import AuthPage from "./AuthPage";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-z]+\.[a-z]{2,}$/, "Email is not valid")
    .email("Email is not valid"),

  password: yup
    .string()
    .required("Password is required")
    .min(8, "Must be at least 8 characters")
    .matches(/[^A-Za-z0-9]/, "Must contain one special character"),
  invitationCode: yup.string().min(0).max(16, "Invitation code cannot be more than 12 characters long")
});

export default function SignUp() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
  } = useForm<IUserRegister>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const [isLengthMet, setIsLengthMet] = useState(false);
  const [isSpecialCharMet, setIsSpecialCharMet] = useState(false);

  const { googleLogin } = useGoogleLoginRequest();

  const password = watch("password");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const invitationCode = params.get("invitationCode");
    if (invitationCode) {
      setValue("invitationCode", invitationCode);
    }
  }, [location.search, setValue]);

  useEffect(() => {
    if (password) {
      setIsLengthMet(password.length >= 8);
      setIsSpecialCharMet(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password));
    } else {
      setIsLengthMet(password?.length >= 8);
      setIsSpecialCharMet(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password));
      clearErrors("password");
    }
  }, [password, clearErrors]);

  const onSubmit = async (data: IUserRegister) => {
    try {
      const result = await signUp(data);
      if (result.accessToken) {
        localStorage.setItem("accessToken", result.accessToken);
        dispatch(getMeData()).then(() => navigate(ROUTES.personalize));
      }
    } catch (error: any) {
      const errorMessage = String(error)?.replace("Error: ", "");

      if (errorMessage.includes(USER_WITH_EMAIL_ALREADY_EXISTS_ERROR_MESSAGE)) {
        navigate(ROUTES.signIn);
      }

      Alert("error", errorMessage, "Error");
    }
  };

  return (
    <AuthPage>
      <div className='w-full md:w-1/2 px-3 min-h-screen flex flex-col justify-center bg-[#F7F9FB]'>
        <div className='h-fit w-full max-w-[420px] flex flex-col mx-auto'>
          <div className='mb-10'>
            <Typography className='mb-4 text-center text-[#1C1C1C]' variant={TypographyVariants.H1}>
              Create <span className='text-[#9A91A8]'>an account</span>
            </Typography>
            <Typography variant={TypographyVariants.LG_MEDIUM} className='text-center text-[#2C1A49]/60'>
              Let’s get started with your free trial.
            </Typography>
          </div>

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className='space-y-4'>
              <Input {...register("name", { required: true })} placeholder='Name' error={errors?.name} icon={InputProfileIcon} />
              <Input {...register("email", { required: true })} placeholder='Email' error={errors?.email} icon={EmailIcon} />
              <Input {...register("invitationCode", { required: false })} placeholder='Invitation code' error={errors?.invitationCode} icon={InvitationCodeIcon} />
              <Input
                {...register("password", { required: true })}
                type='password'
                placeholder='Password'
                error={errors?.password}
                icon={LockIcon}
              />

              <div className='flex flex-col gap-2'>
                <Typography
                  variant={TypographyVariants.MD_MEDIUM}
                  className={cn("flex items-center font-normal text-[#9A91A8] gap-2", { "text-[#1F2228]": isLengthMet })}
                >
                  <CheckIcon key={"check-1"} fill={isLengthMet ? "#6A5EEE" : "#A6A6A6"} className='pointer-events-none w-6 h-6' />
                  Must be at least 8 characters
                </Typography>
                <Typography
                  variant={TypographyVariants.MD_MEDIUM}
                  className={cn("flex items-center font-normal text-[#9A91A8] gap-2", { "text-[#1F2228]": isSpecialCharMet })}
                >
                  <CheckIcon fill={isSpecialCharMet ? "#6A5EEE" : "#A6A6A6"} key={"check-2"} className='pointer-events-none w-6 h-6' />
                  Must contain one special character
                </Typography>
              </div>
            </div>
            <Button disabled={!!errors.email || !!errors.name || !!errors.password} className='mt-8 mb-4'>
              Sign up
            </Button>
          </form>

          {/*<GoogleButton onClick={() => googleLogin()}>Sign Up with Google</GoogleButton>*/}

          <Typography variant={TypographyVariants.MD_MEDIUM} className='mt-6 inline-flex justify-center items-center'>
            Already have an account?
            <LinkButton to={ROUTES.signIn} variant={ButtonVariants.LINK_TEXT_MEDIUM} className='ml-2'>
              Log in
            </LinkButton>
          </Typography>
        </div>
        <Typography className='mt-14 mx-auto text-[#9A91A8] text-center' variant={TypographyVariants.MD_REGULAR}>
          By continuing you agree our
          <LinkButton className='mx-1' to={ROUTES.signUp} variant={ButtonVariants.LINK_TEXT_MEDIUM}>
            Terms of Service
          </LinkButton>
          and
          <LinkButton className='mx-1' to={ROUTES.signUp} variant={ButtonVariants.LINK_TEXT_MEDIUM}>
            Privacy
          </LinkButton>
        </Typography>
      </div>
    </AuthPage>
  );
}
