import Alert from "../shared/components/toasts";

export const showServerError = (error: unknown) => {
  // TODO - remove temporary type below

  const err = error as {
    response: {
      data: {
        errors?: string[];
      };
    };
  };
  const message = err?.response?.data?.errors?.[0] || "Something went wrong. Please, try again.";
  // TODO - create alert helpers functions
  Alert("error", message, "Error");
};
