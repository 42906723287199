import { useEffect } from "react";
import { Bounce, ToastContainer } from "react-toastify";

import { PagesWrapper } from "./page-components/PagesWrapper";

import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    if (!localStorage.getItem("watchedInfo")) {
      localStorage.setItem("watchedInfo", "false");
    }
  }, []);

  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme='light'
        transition={Bounce}
      />
      <PagesWrapper />
    </>
  );
}

export default App;
