import { type FC } from "react";

import { useAppSelector } from "../../../../../hooks/appHooks";
import CurveChart from "../../../../../shared/components/budget/curveChart/CurveChart";

import classes from "./BudgetAllocation.module.scss";
import Budgets from "./Budgets/Budgets";
import Metrics from "./Metrics/Metrics";

interface BudgetAllocationProps {
  openChat: boolean;
}

const BudgetAllocation: FC<BudgetAllocationProps> = ({ openChat = false }) => {
  return (
    <section className={classes.allocationSection}>
      <Budgets />
      <Metrics openChat={openChat} />
    </section>
  );
};

export default BudgetAllocation;
