import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";

import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";

import { ProjectListItem } from "interfaces/projects.interfaces";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { getProjectsList } from "storage/actions/project";
import { openPopup } from "storage/slices/popup-slice";

import BudgetCard from "shared/components/budget/budgetCard/BudgetCard";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as AddNew } from "assets/icons/addNew.svg";
import { ReactComponent as SortButton } from "assets/icons/downButton.svg";

import { generateUniqueId } from "utils";

export default function Budget() {
  const dispatch = useAppDispatch();
  const projectsState = useAppSelector(state => state.project);
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");

  useEffect(() => {
    dispatch(getProjectsList({ page: 1 }));
  }, []);

  function sortProjectsDescending(projects: ProjectListItem[]): ProjectListItem[] {
    if (projects && projects.length) {
      return [...projects].sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime());
    }

    return [];
  }

  function sortProjectsAscending(projects: ProjectListItem[]): ProjectListItem[] {
    if (projects && projects.length) {
      return [...projects].sort((a, b) => new Date(a.date_created).getTime() - new Date(b.date_created).getTime());
    }

    return [];
  }

  const sortedProjects = React.useMemo(() => {
    return sortOrder === "desc" ? sortProjectsDescending(projectsState?.projectList) : sortProjectsAscending(projectsState?.projectList);
  }, [projectsState?.projectList, sortOrder]);

  const handleSortButtonClick = () => {
    setSortOrder(prevSortOrder => (prevSortOrder === "desc" ? "asc" : "desc"));
  };

  return (
    <DashboardLayout title='Budget Intelligence'>
      <div className='bg-[#F7F9FB] rounded-xl flex-1 min-h-full flex flex-col items-start gap-y-6 py-6 px-5'>
        <div className='relative flex items-center justify-between w-full'>
          <div className='absolute cursor-pointer inset-y-0 start-0 flex items-center ps-3 '>
            <svg style={{ cursor: "pointer" }} width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z'
                stroke='#2C1A49'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path opacity='0.4' d='M22 22L20 20' stroke='#2C1A49' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </div>
          <input
            type='search'
            className={twMerge("max-w-[325px] py-[9px] pl-10 pr-3.5 ", INPUT_SHARED_CLASSNAME)}
            placeholder='Search budgets'
            required
          />
          <div className='flex items-center cursor-pointer gap-x-2'>
            <div className='text-[#a6a6a6] font-medium text-sm' onClick={handleSortButtonClick}>
              {sortOrder === "desc" ? "Older" : "Newest"}
            </div>
            <SortButton />
          </div>
        </div>
        <div className='flex items-center flex-wrap w-full gap-3'>
          <div
            className='max-w-[342px] w-full flex flex-col p-3 border-2 border-dashed border-[#766CE4] rounded-2xl cursor-pointer gap-y-3'
            onClick={() => dispatch(openPopup("CreateBudget"))}
          >
            <div className='flex items-center gap-x-2 text-[#766CE4] text-md font-bold'>
              <AddNew /> New budget
            </div>
            <div className='text-[#a6a6a6] text-sm font-normal'>
              Create a new budget and start to
              <br /> automate your customer touch points.
            </div>
          </div>
          {sortedProjects?.map((project: ProjectListItem) => <BudgetCard key={generateUniqueId()} project={project} />)}
        </div>
      </div>
    </DashboardLayout>
  );
}
