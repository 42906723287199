import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router";

import { ROUTES } from "constants/routes.constants";

import { googleAuth } from "storage/actions/auth";

import { useAppDispatch } from "./appHooks";

export const useGoogleLoginRequest = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse: { access_token: string }) => {
      dispatch(googleAuth({ token: codeResponse.access_token }))
        .unwrap()
        .then(res => {
          if (res?.accessToken) {
            navigate(ROUTES.dashboard);
          }
        });
    },
    onError: error => console.error("Login Failed:", error),
  });

  return { googleLogin };
};
