import React from "react";

import { BoxVariants } from "constants/shared/box.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { Box } from "shared/components/Box";
import { Typography } from "shared/components/Typography";
import { WithLogoLayout } from "shared/layouts/WithLogoLayout";

import { PersonalizationForm } from "page-components/personalization/PersonalizationForm";

import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";

export const Personalize = () => {
  return (
    <WithLogoLayout hideBackButton>
      <Box variant={BoxVariants.MINIFIED}>
        <div className='text-center'>
          <ProfileIcon className='mx-auto w-16 h-16' />
          <Typography variant={TypographyVariants.H1} className='my-3.5'>
            Personalise <span className='text-[#9A91A8]'>your account</span>
          </Typography>
          <Typography className='text-[#2C1A49]/60 !font-semibold text-nowrap' variant={TypographyVariants.LG_MEDIUM}>
            Help us customize your experience by telling us a bit about yourself and your goals.
          </Typography>
        </div>
        <div className='mt-10'>
          <PersonalizationForm />
        </div>
      </Box>
    </WithLogoLayout>
  );
};
