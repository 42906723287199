import { MembershipPlanDetails } from "interfaces/memberships.interfaces";

import { ReactComponent as BriefCaseIcon } from "assets/icons/briefcase-1.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as RocketIcon } from "assets/icons/rocket.svg";

import { getMembershipYearOrMonthLabel } from "../utils/memberships.utils";

import { UserPlans } from "./user.constants";

export const MEMBERSHIP_PLANS_FREE_ADVANTAGES = [
  {
    checked: true,
    label: "1 budget workspace",
  },
  {
    checked: true,
    label: "1 workflow creation",
  },
  {
    checked: true,
    label: "1 user seat",
  },
  {
    checked: true,
    label: "Features List",
  },
  {
    checked: false,
    label: "Features List",
  },
  {
    checked: false,
    label: "Features List",
  },
];

export const MEMBERSHIP_PLANS_PRO_ADVANTAGES = [
  {
    checked: true,
    label: "Unlimited budget workspaces",
  },
  {
    checked: true,
    label: "Unlimited workflow creation",
  },
  {
    checked: true,
    label: "250 Generative AI credits",
  },
  {
    checked: true,
    label: "1 user seat",
  },
  {
    checked: false,
    label: "Features List",
  },
  {
    checked: false,
    label: "Features List",
  },
];

export const MEMBERSHIP_PLANS_ENTERPRISE_ADVANTAGES = [
  {
    checked: true,
    label: "Unlimited budget workspaces",
  },
  {
    checked: true,
    label: "Unlimited workflow creation",
  },
  {
    checked: true,
    label: "750 Generative AI credits",
  },
  {
    checked: true,
    label: "Up to 3 user seats",
  },
  {
    checked: false,
    label: "Features List",
  },
  {
    checked: false,
    label: "Features List",
  },
];

export const MEMBERSHIPS_PLANS_DETAILS: Record<UserPlans, MembershipPlanDetails> = {
  [UserPlans.FREE]: {
    icon: RocketIcon,
    priceLabel: () => "7 days free trial",
    description: "Begin your journey to data-driven marketing with our basic plan.",
    advantages: MEMBERSHIP_PLANS_FREE_ADVANTAGES,
  },
  [UserPlans.PRO]: {
    icon: PersonIcon,
    priceLabel: (isYearly) => `per user / ${getMembershipYearOrMonthLabel(isYearly)}`,
    description: "Advanced AI features to create channel specific budget and campaigns.",
    advantages: MEMBERSHIP_PLANS_PRO_ADVANTAGES,
    showSaveLabel: true,
  },
  [UserPlans.ENTERPRISE]: {
    icon: BriefCaseIcon,
    priceLabel: (isYearly) => `3 users / ${getMembershipYearOrMonthLabel(isYearly)}`,
    description: "Advanced AI features to create multiple budget workspaces & collaborate on campaigns.",
    advantages: MEMBERSHIP_PLANS_ENTERPRISE_ADVANTAGES,
  },
};
