import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { showServerError } from "utils/modules.utils";

import { useAppDispatch } from "hooks/appHooks";

import { setLoading } from "storage/slices/loader-slice";
import { closePopup } from "storage/slices/popup-slice";
import { changeMethod, setUploadedPoject } from "storage/slices/project-slice";

import { ReactComponent as AiIcon } from "assets/icons/aiStartIcon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/closeButton.svg";
import { ReactComponent as BudgetIcon } from "assets/icons/upload-popup/budgetDetectionIcon.svg";
import { ReactComponent as OptimizationIcon } from "assets/icons/upload-popup/spendOptimizationIcon.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload-popup/uploadIcon.svg";
import { ReactComponent as WorkflowIcon } from "assets/icons/upload-popup/workflowAutomationIcon.svg";

import Button from "../../auth-buttons/Button";
import { LinkButton } from "../../buttons/LinkButton";
import FileLoader from "../../fileLoader/FileLoader";
import Alert from "../../toasts";
import { Typography } from "../../Typography";

export default function UploadBudgetPopup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<"select" | "uploading" | "done">("select");

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        Alert("error", "File is too large. Maximum file size is 10MB.", "Error");
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      const file = event.dataTransfer.items[0].getAsFile();
      if (file && file.size <= MAX_FILE_SIZE) {
        setSelectedFile(file);
      } else {
        Alert("error", "File is too large. Maximum file size is 10MB.", "Error");
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const clearFileInput = () => {
    setSelectedFile(null);
    setProgress(0);
    setUploadStatus("select");
  };

  const handleUpload = async () => {
    if (
      selectedFile?.type !== "text/csv" &&
      selectedFile?.type !== "application/vnd.ms-excel" &&
      selectedFile?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      Alert("info", "File must be only .csv, .xls, .xlsx", "Invalid file type");
      return;
    }

    try {
      setUploadStatus("uploading");

      const formData = new FormData();
      if (selectedFile) {
        formData.append("file", selectedFile);
      }
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/projects/upload-file`, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });
      if (response.status === 200 || response.status === 201) {
        dispatch(setUploadedPoject(response?.data));
        continueHandler();
      }
    } catch (error) {
      setSelectedFile(null);
      setProgress(0);
      setUploadStatus("select");
      dispatch(setLoading(false));
      showServerError(error);
      navigate(ROUTES.budget);
    }
  };

  const continueHandler = () => {
    dispatch(closePopup());
    dispatch(changeMethod("upload"));
    navigate(ROUTES.budgetForm);
  };

  return (
    <div className='flex flex-col bg-[#fff] shadow-sm rounded-3xl pointer-events-auto relative py-16 px-20'>
      <button
        type='button'
        className='absolute w-9 h-9 top-6 right-6 flex justify-center items-center rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none'
        data-hs-overlay='#hs-static-backdrop-modal'
        onClick={() => {
          clearFileInput();
          dispatch(closePopup());
        }}
      >
        <CloseIcon />
      </button>
      <div className='flex flex-col items-center'>
        <Typography className='mb-10' variant={TypographyVariants.H3}>
          Upload existing budget
        </Typography>
        <div className='flex flex-col border-[1.5px] w-[635px] border-[#C0BBF3] px-[47.5px] pb-6 pt-5 rounded-[14px] gap-y-5 mb-6'>
          <div className='flex justify-center items-center font-bold text-base gap-x-2.5'>
            <AiIcon className='w-8 h-8' />
            <Typography variant={TypographyVariants.H7}>Maximize your ROI with AI-powered budget management</Typography>
          </div>
          <div className='flex items-center justify-between gap-x-6'>
            <Typography
              className='flex items-center gap-x-2 text-[#464A53] whitespace-nowrap'
              tag='span'
              variant={TypographyVariants.MD_MEDIUM}
            >
              <BudgetIcon className='w-6 h-6' /> Budget detection
            </Typography>
            <Typography
              className='flex items-center gap-x-2 text-[#464A53] whitespace-nowrap'
              tag='span'
              variant={TypographyVariants.MD_MEDIUM}
            >
              <OptimizationIcon className='w-6 h-6' /> Spend optimization
            </Typography>
            <Typography
              className='flex items-center gap-x-2 text-[#464A53] whitespace-nowrap'
              tag='span'
              variant={TypographyVariants.MD_MEDIUM}
            >
              <WorkflowIcon className='w-6 h-6' /> Workflow automation
            </Typography>
          </div>
        </div>
        <div className='w-full h-full'>
          <div className='flex max-h-[249px] h-full pt-6 px-4 pb-4 justify-center bg-[#fff] dashed-border rounded-[21px] w-full'>
            <div className='flex flex-col justify-between items-center text-center'>
              {!selectedFile ? (
                <>
                  <UploadIcon className='w-12 h-12' />
                  <div onDrop={handleDrop} onDragOver={handleDragOver}>
                    <div className='pt-6 flex flex-wrap justify-center text-sm leading-6 text-gray-600'>
                      <p className='pe-1 text-base font-bold text-[#464A53]'>
                        Drag and drop <span className='font-medium'>or</span>
                      </p>
                      <label className='relative cursor-pointer bg-white font-bold text-base text-[#766CE4] hover:text-[#766CE4] rounded-lg decoration-2 hover:underline outline-none'>
                        <span>Click to Upload</span>
                        <input
                          ref={inputRef}
                          type='file'
                          onChange={handleFileChange}
                          className='sr-only'
                          accept='.csv, .xls, .xlsx, excel/*'
                        />
                      </label>
                    </div>
                    <Typography variant={TypographyVariants.MD_REGULAR} className='text-[#717684] mt-2'>
                      You may upload spreadsheets in Excel or CSV format
                    </Typography>
                    <Typography variant={TypographyVariants.SM_REGULAR} className='text-[#9EA2AD] mt-2 mb-10'>
                      (Max. File size: 10 MB)
                    </Typography>
                  </div>
                </>
              ) : (
                <FileLoader
                  name={selectedFile?.name}
                  format={selectedFile?.name?.split(".").pop()}
                  progress={progress}
                  status={uploadStatus}
                  handleUpload={handleUpload}
                  clearFileInput={clearFileInput}
                />
              )}
              <div className='flex items center'>
                <Typography className='text-[#717684]' variant={TypographyVariants.SM_REGULAR}>
                  By continuing you agree our
                  <LinkButton variant={ButtonVariants.LINK_TEXT_MEDIUM} className='text-xs text-[#9EA2AD] mx-1' to='/'>
                    Terms of Service
                  </LinkButton>
                  and{" "}
                  <LinkButton variant={ButtonVariants.LINK_TEXT_MEDIUM} className='text-xs text-[#9EA2AD] mx-1' to='/'>
                    Privacy Policy
                  </LinkButton>
                </Typography>
                {/*<p className='text-[#9EA2AD] underline font-normal  cursor-pointer'>Terms of Service</p> and{" "}*/}
                {/*<p className='text-[#9EA2AD] underline font-normal cursor-pointer'>Privacy Policy</p>.*/}
              </div>
            </div>
          </div>
        </div>
        {selectedFile && (
          <Button classes='!w-[200px] !rounded-[12px] !text-sm !h-[36px] !px-[42px] mt-10 !w-fit' onClick={handleUpload} disabled={uploadStatus === "uploading"}>
            Continue
          </Button>
        )}
      </div>
    </div>
  );
}
