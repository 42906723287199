import { useNavigate } from "react-router-dom";

import { ProjectListItem } from "interfaces/projects.interfaces";

import { useAppDispatch } from "hooks/appHooks";

import { getOneProject } from "storage/actions/project";

import { ReactComponent as DotsIcon } from "assets/icons/dots.svg";
import { ReactComponent as WalletIcon } from "assets/icons/smallWallet.svg";

interface IBudgetCardProps {
  project: ProjectListItem;
}

export default function BudgetCard({ project }: IBudgetCardProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const date = new Date(project.date_created);
  let formattedDate = "Invalid Date";
  const formattedName = project?.name.length > 25 ? project?.name.substring(0, 25) + "..." : project?.name;

  if (!isNaN(date.getTime())) {
    formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  const getProjectHandler = async () => {
    await dispatch(getOneProject(Number(project.project_id)));
    navigate(`/budget/${project.project_id}/budget-intelligence`);
  };

  return (
    <div
      className='max-w-[342px] w-full flex flex-col p-3 cursor-pointer bg-[#fff] rounded-2xl gap-y-3 relative '
      onClick={getProjectHandler}
    >
      <div className='absolute top-2 right-3 cursor-pointer'>
        <DotsIcon />
      </div>
      <div className='flex items-center gap-x-2 text-md font-bold '>
        <WalletIcon className='min-w-[42px]' />{" "}
        <span className='inline-block max-w-[300px]  whitespace-nowrap' title={project?.name}>
          {formattedName || formattedDate}
        </span>
      </div>
      <div className=' text-sm font-normal'>
        Created
        <br /> <span className='text-[#a6a6a6]'>{formattedDate}</span>
      </div>
    </div>
  );
}
