export const stepFormConfig = [
  {
    tabIndex: 1,
    title: "Define your Initiative",
    description: "Helps us tailor support to your <br/> project's specific needs.",
  },
  {
    tabIndex: 2,
    title: "Set your objective",
    description: "Necessary to optimize resource <br/> usage for impactful campaigns.",
  },

  {
    tabIndex: 3,
    title: "Yearly marketing strategy",
    description: "Necessary to optimize resource <br/> usage for impactful campaigns.",
  },
  // {
  //   tabIndex: 4,
  //   title: 'Company revenue and growth',
  //   description: 'Necessary to provide custom <br/> scalable strategies.',
  // },
];
