import cn from "classnames";
import React from "react";
import { useNavigate } from "react-router";

import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { UserPlans } from "constants/user.constants";

import { useSubscriptionPlanInfo } from "hooks/subscriptions/useSubscriptionPlanInfo";

import { Box } from "../Box";
import { ButtonWithGradient } from "../buttons/ButtonWithGradient";
import { LinkButton } from "../buttons/LinkButton";
import { ProgressLine } from "../ProgressLine";

interface Props {
  isSimplified?: boolean;
  hidden?: boolean;
}

export const TrialPeriodSection = ({ isSimplified, hidden }: Props) => {
  const navigate = useNavigate();

  const { percentage, fullDateString, userPlan } = useSubscriptionPlanInfo();

  const handleNavigateToMemberships = () => {
    navigate(ROUTES.memberships);
  };

  if (userPlan !== UserPlans.FREE) return null;

  if (isSimplified)
    return (
      <div
        className={cn("flex items-center mr-6 transition-all duration-300", {
          "invisible opacity-0": hidden,
        })}
      >
        {fullDateString !== null && <p className='text-sm text-[#2E323A] font-medium text-nowrap'>{fullDateString} remaining</p>}
        <LinkButton to={ROUTES.memberships} variant={ButtonVariants.MEDIUM_PREMIUM} className='ml-5 w-full py-2 px-[42px] bg-[#766CE4]'>
          Upgrade{" "}
        </LinkButton>
      </div>
    );

  return (
    <Box
      className={cn("p-4 transition-all duration-300 w-full bg-[#F9F9FF] rounded-[20px]", {
        "invisible opacity-0": hidden,
      })}
    >
      {fullDateString !== null && (
        <>
          <div className='flex justify-between'>
            <p className='text-sm text-[#544DA2] font-semibold text-nowrap'>Free trial</p>
            <p className='text-sm text-[#464A53] font-medium ml-2 text-right'>{fullDateString} left</p>
          </div>
          <ProgressLine className='mt-4 mb-4' progress={percentage < 0 ? 0 : percentage} />
        </>
      )}
      <ButtonWithGradient onClick={handleNavigateToMemberships}>Upgrade</ButtonWithGradient>
    </Box>
  );
};
