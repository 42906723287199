import cn from "classnames";
import React from "react";


import { MembershipPlanDetailsAdvantage } from "interfaces/memberships.interfaces";

import { ReactComponent as CheckMarkIcon } from "assets/icons/check-mark.svg";
import { ReactComponent as CloseIcon } from "assets/icons/closeButton.svg";

export const MembershipAdvantagesListItem = ({ checked, label }: MembershipPlanDetailsAdvantage) => {
  return (
    <li className='flex items-center'>
      <div
        className={cn("w-[22px] h-[22px] flex justify-center items-center rounded-full border", {
          "bg-[#D5D1F7] border-[#292447] text-[#292447]": checked,
        })}
      >
        {checked ? <CheckMarkIcon /> : <CloseIcon className='[&_rect]:stroke-[#717684] [&_path]:stroke-[#717684]' />}
      </div>
      <p className='ml-2.5 text-interTight text-[#292447] font-medium leading-6'>{label}</p>
    </li>
  );
};
