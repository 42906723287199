import { IUserData } from "api/user/types";

import { BrowserStorageKeys } from "constants/browser-storage.constants";
import { UserPlans } from "constants/user.constants";

import { PendoInitializePayload } from "interfaces/3rd-party/pendo.interfaces";
import { MembershipPlan } from "interfaces/memberships.interfaces";

export const identifyPendo = ({
  pendoVisitorId,
  membership,
  ...user
}: IUserData & { membership: MembershipPlan; pendoVisitorId: string }) => {
  const {
    user_id,
    email,
    name,
    date_created,
    plan: { name_tech },
  } = user;

  const { price_monthly: priceMonthly, price_yearly: priceYearly } = membership;

  const identifyConfigPayload: PendoInitializePayload = {
    visitor: { id: pendoVisitorId, email: email, full_name: name, role: name_tech, creationDate: date_created },
    account: { id: user_id, name: name_tech, is_paying: name_tech === UserPlans.FREE, monthly_value: priceMonthly, planLevel: priceYearly },
  };

  (window as any).pendo?.identify(identifyConfigPayload);
  (window as any).pendo?.initialize(identifyConfigPayload);

  localStorage.setItem(BrowserStorageKeys.IS_PENDO_INITIALIZED, "true");
};

export const clearPendoBrowserData = () => {
  localStorage.removeItem(BrowserStorageKeys.IS_PENDO_INITIALIZED);
};
