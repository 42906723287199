import { ConstraintVariants } from "constants/shared/constraint.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppSelector } from "hooks/appHooks";

import { selectIsMembershipPlanExists } from "storage/selectors/user";

import { Constraint } from "shared/components/Constraint";
import { Tabs } from "shared/components/tabs/Tabs";
import { Typography } from "shared/components/Typography";
import { WithLogoLayout } from "shared/layouts/WithLogoLayout";

import { MembershipCardsContainer } from "page-components/memberships/MembershipCardsContainer";

import { usePaymentStatus } from "../../hooks/payments/usePaymentStatus";

const MEMBERSHIPS_PAGE_TABS = [
  {
    id: "monthly",
    title: "Monthly",
    component: MembershipCardsContainer,
  },
  {
    id: "yearly",
    title: "Yearly",
    description: "(Save 20%)",
    component: () => <MembershipCardsContainer isYearly />,
  },
];

export const Memberships = () => {
  const isMembershipPlanExists = useAppSelector(selectIsMembershipPlanExists);
  return (
    <WithLogoLayout childrenClassName='mt-8' hideBackButton={!isMembershipPlanExists}>
      <Constraint variant={ConstraintVariants.LARGE} className='px-0 xl:px-4'>
        <Typography className='text-center' variant={TypographyVariants.H1} tag='h1'>
          Start your <span className='text-[#2C1A49]/40'>free trial </span>
        </Typography>

        <Tabs className='mt-10' contentClassName='px-0 xl:px-4' tabs={MEMBERSHIPS_PAGE_TABS} />
      </Constraint>
    </WithLogoLayout>
  );
};
