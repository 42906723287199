import * as echarts from "echarts";
import React, { useEffect, useRef, FC } from "react";


interface ICurveChart {
  title?: string;
  color?: string;
  background?: string[];
  isCollapsed?: boolean;
  openChart?: boolean;
}

const CurveChart: FC<ICurveChart> = ({
  title = "Title",
  color = "#6B62CF",
  background = ["rgb(167, 162, 232)", "rgb(255, 255, 255)"],
  isCollapsed = false,
  openChart = false,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);
    const option = {
      color: [color],

      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "grey",
          },
        },
      },
      legend: {},
      toolbox: {
        feature: {},
      },
      grid: {
        left: "0",
        right: "0%",
        bottom: "0",
        containLabel: false,
      },
      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLine: {
            lineStyle: {
              color: "transparent", // Set color to transparent to hide the axis line
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            show: false, // Hiding the value labels on the Y-axis
          },
          axisTick: {
            show: false, // Hide the axis ticks as well
          },
          splitLine: {
            show: true, // Show the split lines
            lineStyle: {
              type: "dashed", // Make the split lines dashed
              width: 2, // Increase the width to make dashes more bold
            },
          },
          interval: 40, // Set the interval for split lines
        },
      ],
      series: [
        {
          //   name: 'Line 1',
          type: "line",
          stack: "Total",
          smooth: true,
          lineStyle: {
            width: 3,
            color: color, // Setting the line color to red
            shadowColor: background[0], // Shadow color
            shadowBlur: 5, // Blur radius of the shadow
            shadowOffsetX: 0, // Horizontal offset of the shadow
            shadowOffsetY: 5, // Vertical offset of the shadow
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: background[0] },
              { offset: 0.8, color: background[1] },
              { offset: 1, color: background[1] },
            ]),
          },
          emphasis: {
            focus: "series",
          },
          data: [5, 76, 65, 70, 73, 71, 85, 92, 87, 97, 110, 0],
        },
      ],
    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [isCollapsed, openChart]);

  return (
    <div
      id='main'
      style={{
        width: "100%",
        height: "172px",
      }}
      ref={chartRef}
    ></div>
  );
};

export default CurveChart;
