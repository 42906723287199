import { toast } from "react-toastify";

import { ReactComponent as ErrorIcon } from "../../../assets/icons/errorIcon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/infoIcon.svg";
import { ReactComponent as ErrorShadow } from "../../../assets/icons/shadowErrorBg.svg";
import { ReactComponent as InfoShadow } from "../../../assets/icons/shadowInfoIcon.svg";
import { ReactComponent as SuccessShadow } from "../../../assets/icons/shadowSuccess.svg";
import { ReactComponent as SuccesIcon } from "../../../assets/icons/successIcon.svg";

const toastStyle = {
  fontSize: "16px",
  color: "white",
  borderRadius: "14px",
  padding: "15px 16px 19px 16px",
  width: "343px",
  height: "82px",
  marginBottom: "12px",
};

const Alert = (type: string, message: string, title: string) => {
  switch (type) {
    case "error":
      return toast.error(
        <div className='flex items-center relative space-x-3 gap-x-3'>
          <ErrorShadow className='absolute top-1/2 transform -translate-y-1/2 left-0 ' />
          <ErrorIcon />{" "}
          <div className='flex flex-col '>
            <div className='flex items-center text-base font-bold'>{title}</div>
            <div className='flex items-center text-[#c8c5c5] text-base font-medium'>{message}</div>
          </div>
        </div>,
        {
          icon: false,
          style: {
            background: "#242C32",
            ...toastStyle,
          },
          progressStyle: {
            background: "#01E17B",
          },
        },
      );
    case "success":
      return toast.success(
        <div className='flex items-center relative space-x-3 gap-x-3'>
          <SuccessShadow className='absolute top-1/2 transform -translate-y-1/2 left-0 ' />
          <SuccesIcon />{" "}
          <div className='flex flex-col '>
            <div className='flex items-center text-base font-bold'>{title}</div>
            <div className='flex items-center text-[#c8c5c5] text-base font-medium'>{message}</div>
          </div>
        </div>,
        {
          icon: false,
          style: {
            background: "#242C32",
            ...toastStyle,
          },
        },
      );
    case "info":
      return toast.info(
        <div className='flex items-center relative space-x-3 gap-x-3'>
          <InfoShadow className='absolute top-1/2 transform -translate-y-1/2 left-0 ' />
          <InfoIcon />
          <div className='flex flex-col '>
            <div className='flex items-center text-base font-bold'>{title}</div>
            <div className='flex items-center text-[#c8c5c5] text-base font-medium'>{message}</div>
          </div>
        </div>,
        {
          icon: false,
          style: {
            background: "#242C32",
            ...toastStyle,
          },
        },
      );

    default:
      return toast(message);
  }
};
export default Alert;
