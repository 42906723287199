import React from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  color: string;
  backgroundColor?: string;
  content: string;
  className?: string;
}

export const ColoredLabel = ({ backgroundColor, color, content, className }: Props) => {
  return (
    <div
      className={twMerge("w-fit py-[3px] px-3 rounded-full text-xs font-semibold select-none", className)}
      style={{ backgroundColor, color }}
    >
      {content}
    </div>
  );
};
