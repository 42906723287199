export enum ModalVariants {
  PRIMARY = "primary",
  NARROW = "narrow",
  SMALL = "small"
}

export const ModalStyles: Record<ModalVariants, string> = {
  [ModalVariants.PRIMARY]: "max-w-[800px]",
  [ModalVariants.NARROW]: "max-w-xl",
  [ModalVariants.SMALL]: "max-w-[412px]"
};
