import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { DESCRIBE_PROJECT_DESCRIPTION_FIELD_VALIDATION } from "constants/describe-project.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";
import { REQUIRED_AND_ONLY_ENGLISH_FIELD_VALIDATION } from "constants/validation.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useDebouncedEffect } from "hooks/useDebouncedEffect";
import { useString } from "hooks/useString";

import { handleProjectForm } from "storage/slices/project-slice";

import { Button } from "shared/components/buttons/Button";
import { HighlightedCountMessage } from "shared/components/form-elements/HighlightedCountMessage";
import { Input } from "shared/components/form-elements/Input";
import { MessageWithCounter } from "shared/components/form-elements/MessageWithCounter";
import { TextArea } from "shared/components/form-elements/TextArea";
import TagInputComponent from "shared/components/tag-input/TagInputComponent";
import { Typography } from "shared/components/Typography";

interface Props {
  nextStepHandler: VoidFunction;
}

export default function DescribeProjectForm({ nextStepHandler }: Props) {
  const dispatch = useAppDispatch();
  const project = useAppSelector(state => state.project.newProjectForm);

  const [audienceError, setAudienceError] = useString();

  const {
    register,
    formState: { errors },
    watch,
    reset,
    trigger,
  } = useForm({ mode: "onChange" });

  const watchDescriptionField = watch("description");
  const values = watch();

  const charCount = watchDescriptionField?.length;

  const onNextStep = async () => {
    const isValidatedCorrectly = await trigger();

    if (!isValidatedCorrectly) return;

    nextStepHandler();
  };

  function validateForm(description: any, name: any, targetAudience: any): boolean {
    const isDescriptionValid = description?.length >= 120;
    const isNameValid = name?.trim() !== "";
    const isTargetAudienceValid = targetAudience?.length > 0;
    return isDescriptionValid && isNameValid && isTargetAudienceValid;
  }

  useEffect(() => {
    if (Object.values(values)?.length) return;
    reset(project);
  }, [project]);

  useDebouncedEffect(
    () => {
      if (!Object.values(values)?.length) return;
      dispatch(handleProjectForm({ ...project, ...values }));
    },
    [dispatch, values?.name, values?.description],
    1000,
  );

  return (
    <div className='bg-[#F7F9FB] rounded-xl w-full flex flex-col h-full gap-y-6 '>
      <Typography variant={TypographyVariants.H2}>
        Describe your <span className='text-[#766CE4]'>project</span>
      </Typography>

      <div className='flex justify-start w-full h-full items-start'>
        <div className='flex flex-col items-start w-full gap-y-6 h-full'>
          <Input
            {...register("name", REQUIRED_AND_ONLY_ENGLISH_FIELD_VALIDATION)}
            label='Project name *'
            placeholder='Type your project name here'
	    maxLength={50}
            error={errors?.name}
          />

          <TextArea
            rows={4}
            {...register("description", DESCRIBE_PROJECT_DESCRIPTION_FIELD_VALIDATION)}
            inputClassName='resize-none overflow-hidden'
            label='Project description *'
            placeholder='For example, we’re a small sized e-commerce brand selling beauty product in Europe. We have a limited annual marketing budget and want to optimize our customer acquisition cost low to drive more sales for our business. I want to know how much we should spend on marketing to yield maximum ROI.'
            error={errors?.description}
          >
            <MessageWithCounter charsCount={charCount} maxChars={500}>
              <HighlightedCountMessage charsCount={charCount} minCount={120} />
            </MessageWithCounter>
          </TextArea>

          <TagInputComponent project={project} projectTags={project.audience} setError={setAudienceError} error={audienceError} />
          <div className='flex justify-end w-full mt-auto 1xl:m-auto 1xl:mb-[140px]'>
            <Button
              className='w-fit'
              variant={ButtonVariants.SMALL}
              disabled={!Boolean(validateForm(project.description, project.name, project.audience))}
              onClick={() => onNextStep()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
