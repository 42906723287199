import React from "react";

import PlusIcon from "../IconsComponents/PlusIcon";

interface TagComponentProps {
  tag: string;
  index: number;
  isExample?: boolean;
  onDelete: (index: number) => void;
}

export default function TagComponent({ tag, isExample, onDelete, index }: TagComponentProps) {
  return (
    <span
      className={`flex items-center flex-nowrap px-2 py-1.5 mr-2 rounded-md text-[#1F2228] text-xs font-medium gap-x-1 
        ${isExample ? "bg-[#E9EAEC]" : "bg-[#F1F0FC]"}
    `}
    >
      {tag}
      {!isExample && (
        <span onClick={() => onDelete(index)}>
          <PlusIcon className='rotate-45 cursor-pointer' />
        </span>
      )}
    </span>
  );
}
