import api from "..";
import { ProjectListItem } from "../../interfaces/projects.interfaces";

import { ICreateProjectData, IProjectData } from "./types";

const BASE_API = process.env.REACT_APP_API_URL;

export const createProject = async (projectData: ICreateProjectData): Promise<IProjectData> => {
  return api(`${BASE_API}/projects`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(projectData),
  });
};

export const getProjectById = async (id: number): Promise<IProjectData> => {
  const data: IProjectData = await api(`${BASE_API}/projects/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

export const getAllProjects = async (page: number): Promise<ProjectListItem[]> => {
  const data: ProjectListItem[] = await api(`${BASE_API}/projects?page=${page}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

export const uploadFile = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);

  return api(`${BASE_API}/projects/upload-file`, {
    method: "POST",
    body: formData,
  });
};
