import { Option } from "../interfaces/shared/form-elements.interfaces";
import { WorkflowStrategyAdditionalFields } from "../interfaces/workflows.interfaces";

export const BRAND_SELECT_OPTIONS = [
  { label: "Empowering", value: "empowering" },
  { label: "Professional", value: "professional" },
  { label: "Vibrant", value: "vibrant" },
  { label: "Creative", value: "creative" },
];

export const GOAL_SELECT_OPTIONS = [
  {
    label: "Increase Sales",
    value: "Increase Sales",
  },
  {
    label: "Increase Traffic",
    value: "Increase Traffic",
  },
  {
    label: "Lower CAC",
    value: "Lower CAC",
  },
  {
    label: "Increase CLTV",
    value: "Increase CLTV",
  },
  {
    label: "Improve CSAT",
    value: "Improve CSAT",
  },
  {
    label: "Increase Revenue",
    value: "Increase Revenue",
  },
  // {
  //   label: "Increasing profit margin",
  //   value: "Increasing profit margin",
  // },
];

export const PERFORMANCE_PLAN_SELECT_DATA: { [key in WorkflowStrategyAdditionalFields]?: { label: string; options: Option[] } } = {
  goal: { label: "What is your goal?", options: GOAL_SELECT_OPTIONS },
  brand: { label: "Choose your brand", options: BRAND_SELECT_OPTIONS },
};
