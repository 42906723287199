export const getMembershipPlanPrice = ({
  priceMonthly,
  priceYearly,
  isYearly,
}: {
  priceMonthly: string;
  priceYearly: string;
  isYearly?: boolean;
}) => {
  const price = isYearly ? priceYearly : priceMonthly;

  return Number(price) === 0 ? "Free" : `$${Number(price)}`;
};

export const getMembershipYearOrMonthLabel = (isYearly?: boolean) => `${isYearly ? "year" : "month"}`;
